export const Default = '/';
export const PageNotFound = '/404';
export const Home = "/home";
export const Cart = '/cart';
export const SignUpFirstUse = '/sign-up-first-use';
export const PayCash = '/pay-cash';
export const Menu = '/menu/:restaurantName/:tableNumber';
export const MenuEdit = '/edit-menu/:product/:typeProduct';
export const Root = "/:restaurantName/:tableNumber";
export const SubMenu = "/sub-menu";
export const SignUp = "/sign-up";
export const Contact = "/Contact"
export const PendingPay = "/PendingPay"
export const SignUpWithPhoneNumber = "/signup-with-phone";
export const Profile = "/profile";
export const MyInformation = "/my-information";
export const Login = "/login";
export const previousOrder = "/previous-order";
export const previousBookings = "/previous-bookings";
export const forgotPassword = "/forgot-password";
export const Changepassword = '/change-password';
export const GetToken = '/get-token';
export const SuccessfulStripe = '/successful-stripe';
export const SuccessfulStripeReservation = '/reservationsuccessful-stripe';
export const UnSuccessfulStripe = '/un-successful-stripe';
export const UnSuccessfulStripeReservation = '/reservationun-successful-stripe';
export const SearchBooking = '/Searchbooking';
export const PickUp = "/pick-up"
export const Reservation = "/table/:restaurantName/:reservation";
export const OurService = "/our-service/:restaurantName/:language"
export const VirtualQueue = "/virtual/:restaurantName/:virtualQueue"
export const Delivery = "/delivery"
export const Tracking = "/tracking"
export const SplitPay = "/split"
export const SplitEvely = "/split-evenly/:id/:count"
export const SplitEvelyPay = "/getToken/:id/:count/:restaurant/:languageStorage/:tableNumber"
export const UnSuccessfulStripeSplit = '/unSuccessfulStripe/:restaurantName/:languageStorage';
export const SplitSelect = "/selectSplit/:id/:restaurantName/:languageStorage/:tableNumber"
export const GetTokenSelect = '/getTokenSelect'
export const SuccefullSelect = '/SuccefullSelect'
export const HotelReservations = "/:restName/Ym9va2luZw==/aG90ZWxSZXNlcnZhdGlvbnM="
export const Pages = "/pages/:restName/:pathId"
export const Reviews = "/reviews/:restaurantName/:tableNumber/:idReservation";
export const ReviewsDetails = "/reviews/find/:key"
export const Services = "/services/:venue"
export const SuccessfulePayco = '/successful-ePayco';
export const GoogleMaps = '/google-maps'
export const DeliveryMan = '/delivery/:lang'
export const PayByCode = '/pay-by-code'
