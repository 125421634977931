import { Box } from "@mui/material"
import { Car, CarRentalIcon, Coffee, LocalMall, Motorcycle, QueryBuilder, RoomService, TableBar } from "./Icons"

//Constants Firebase DataBase
export const restaurantName: string = "restaurantName"
export const idDynamic: string = "idDynamic"
export const products: string = "products"
export const rooms: string = "rooms"
export const colors: string = "colors"
export const credit: string = "credit"
export const logo: string = "logo"
export const hotelLogo: string = "hotelLogo"
export const orders: string = "orders"
export const idOrders: string = "idOrders"
export const idDiscount: string = "idDiscount"
export const stores: string = "stores"
export const tableNumber: string = "tableNumber"
export const language: string = "language"
export const idPickupCurrent: string = "idPickupCurrent"
export const statePickupCurrent: string = "statePickupCurrent"
export const options: string = 'options'
export const relationship: string = 'relationship'
export const removed: string = "Removed"
export const ingredients: string = "Ingredients"
export const extra: string = "Sides"
export const services: string = "services"
export const personal: string = "personal"
export const typeD: string = "typeD"
export const delivery: string = "delivery"
export const textItem: any = {
  ingredientChooseRemove: "Removed",
  extraIngredientsChoose: "Ingredients",
  sideChoose: "Sides"
}
export const serviceState = {
  pendingPayment: "pending payment",
  partialPayment: "partial payment",
  fullyPaid: "fully paid"
}

//Mode travel of the google maps
export const travelMode: any = {
  WALKING: "WALKING",
  DRIVING: "DRIVING"
}

//Keys data base to orderes
export const keyOrders = {
  products: "products",
  state: "state",
  stateVal: { ordered: "ordered", paid: "paid", delivered: "delivered" },
  extraValue: "extraValue",
  sideValue: "sideValue",
  totalAmount: "totalAmount",
  category: "category",
  categoryId: "categoryId",
  productName: "productName",
  productId: "productId",
  idP: "idP",
  productValue: "productValue",
  quantity: "quantity",
  complementary: "complementary",
  cookLevel: "cookLevel",
  ingredientChooseRemove: "ingredientChooseRemove",
  extraIngredientsChoose: "extraIngredientsChoose",
  extraIngredientsChooseVal: { amount: "amount", extraIng: "extraIng" },
  sideChoose: "sideChoose",
  sideChooseVal: { amount: "amount", side: "side" },
  combo: "combo",
  comboKey: { amount: "amount", drink: "drink", meal: "meal", side: "side", status: "status", type: "type", ingredientsDrinkRemove: "ingredientsDrinkRemove" },
  restaurantName: "restaurantName",
  isSelected: 'isSelected',
  OtherSections: 'OtherSections',
  otherSectionsValue: 'otherSectionsValue',
  totalAmountPaid: 'totalAmountPaid',
  zonePrice: 'zonePrice'
}

export const optionConfigOurServices = [
  { label: 'dineIn', value: 'dineIn', icon: <Box component='img' sx={{ width: 30 }} src={Coffee} />, key: 'dineIn' },
  { label: 'takeAway', value: 'takeAway', icon: <Box component='img' sx={{ width: 30 }} src={LocalMall} />, key: 'pickup' },
  { label: 'driveThru', value: 'driveThru', icon: <Box component='img' sx={{ width: 30 }} src={Car} />, key: 'driveThru' },
  { label: 'delivery', value: 'delivery', icon: <Box component='img' sx={{ width: 30 }} src={Motorcycle} />, key: 'delivery' },
  { label: 'roomService', value: 'roomService', icon: <Box component='img' sx={{ width: 30 }} src={RoomService} />, key: 'roomService' },
  { label: 'rentCar', value: 'rentCar', icon: <Box component='img' sx={{ width: 30 }} src={CarRentalIcon} />, key: 'rentCar' },
  { label: 'hotelBook', value: 'hotelBook', icon: <Box component='img' sx={{ width: 30 }} src={RoomService} />, key: 'reservation' },
  { label: 'bookTables', value: 'bookTables', icon: <Box component='img' sx={{ width: 30 }} src={TableBar} />, key: 'booking' },
  { label: 'virtualQueue', value: 'virtualQueue', icon: <Box component='img' sx={{ width: 30 }} src={QueryBuilder} />, key: 'virtualQueue' },
];