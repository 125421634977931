import { createRoot } from 'react-dom/client';

import CssBaseline from '@mui/material/CssBaseline';
import LogRocket from 'logrocket';
import { ThemeProvider } from '@mui/material/styles';

import 'react-phone-number-input/style.css'

// import ErrorBoundary from "./components/Error/ErrorBoundary";
import theme from './theme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { App } from './App';

import './font.css'

const isprod = process.env.REACT_APP_IS_PROD ?? "";

if (isprod && isprod === "true") LogRocket.init('7gezem/order-and-eat-now');

createRoot(document.getElementById('root') as HTMLElement).render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {/* <ErrorBoundary> */}
    <App />
    {/* </ErrorBoundary> */}
  </ThemeProvider>
);

serviceWorkerRegistration.register();