import { Skeleton, Stack } from "@mui/material"
import { Card, Box, Grid } from "@mui/material"

interface IProps {
    welcome?: boolean,
    menu?: boolean,
    editMenu?: boolean,
    cart?: boolean,
    subMenu?: boolean,
    profile?: boolean,
    myInformation?: boolean,
    orders?: boolean,
    login?: boolean,
    loginOpt?: boolean,
    signUp?: boolean,
    getToken?: boolean,
    signUpFirst?: boolean,
    signUpOption?: boolean,
    delivery?: boolean,
    appbar?: boolean,
}

const SkeletonLoading = ({ welcome, menu, editMenu, cart, subMenu, profile, myInformation, orders, login, loginOpt, signUp, getToken, signUpFirst, signUpOption, delivery, appbar }: IProps) => {
    return (
        <Box>
            {
                welcome && (
                    <Card style={{ boxShadow: "none", margin: "0px 15px", }}>
                        <Skeleton animation="wave" height={300} width="100%" />
                        <Grid container spacing={3} justifyContent="space-around" alignItems="center" alignContent="space-between">
                            <Skeleton height={120} width="45%" animation="wave" variant="rectangular" />
                            <Skeleton height={120} width="45%" animation="wave" variant="rectangular" />
                        </Grid>
                        <Grid container spacing={3} justifyContent="space-around" alignItems="center" alignContent="space-between" style={{ marginTop: "30px" }}>
                            <Skeleton height={120} width="45%" animation="wave" variant="rectangular" />
                            <Skeleton height={120} width="45%" animation="wave" variant="rectangular" />
                        </Grid>
                        <Grid container spacing={3} justifyContent="space-around" alignItems="center" alignContent="space-between" style={{ marginTop: "30px" }}>
                            <Skeleton height={120} width="45%" animation="wave" variant="rectangular" />
                            <Skeleton height={120} width="45%" animation="wave" variant="rectangular" />
                        </Grid>
                        <Grid container spacing={3} justifyContent="space-around" alignItems="center" alignContent="space-between" style={{ marginTop: "30px" }}>
                            <Skeleton height={120} width="45%" animation="wave" variant="rectangular" />
                            <Skeleton height={120} width="45%" animation="wave" variant="rectangular" />
                        </Grid>
                    </Card>
                )
            }
            {
                menu && (
                    <Card style={{ boxShadow: "none", margin: "15px" }}>
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Grid container style={{ padding: "15px" }}>
                            <Skeleton animation="wave" variant="circular" width={50} height={50} style={{ marginRight: "20px" }} />
                            <Skeleton animation="wave" variant="circular" width={50} height={50} style={{ marginRight: "20px" }} />
                            <Skeleton animation="wave" variant="circular" width={50} height={50} style={{ marginRight: "20px" }} />
                            <Skeleton animation="wave" variant="circular" width={50} height={50} style={{ marginRight: "20px" }} />
                        </Grid>
                        <Skeleton height={80} animation="wave" variant="rectangular" style={{ marginTop: "20px" }} />
                        <Skeleton height={80} animation="wave" variant="rectangular" style={{ marginTop: "20px" }} />
                        <Skeleton height={80} animation="wave" variant="rectangular" style={{ marginTop: "20px" }} />
                        <Skeleton height={80} animation="wave" variant="rectangular" style={{ marginTop: "20px" }} />
                        <Skeleton height={80} animation="wave" variant="rectangular" style={{ marginTop: "20px" }} />
                        <Skeleton height={80} animation="wave" variant="rectangular" style={{ marginTop: "20px" }} />
                    </Card>
                )
            }
            {
                subMenu && (
                    <Card style={{ boxShadow: "none", margin: "15px" }}>
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton height={40} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={40} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={40} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={40} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={40} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={400} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                    </Card>
                )
            }
            {
                editMenu && (
                    <Card style={{ boxShadow: "none", margin: "15px" }}>
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton height={300} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={200} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={100} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                    </Card>
                )
            }
            {
                cart && (
                    <Card style={{ boxShadow: "none", margin: "15px" }}>
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton height={60} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={150} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={30} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={50} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                    </Card>
                )
            }
            {
                orders && (
                    <Card style={{ boxShadow: "none", margin: "15px" }}>
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton height={60} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={150} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={60} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={150} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                    </Card>
                )
            }
            {
                profile && (
                    <Card style={{ boxShadow: "none", margin: "4px" }}>
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton height={60} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={200} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                    </Card>
                )
            }
            {
                myInformation && (
                    <Card style={{ background: "white", position: "absolute", top: "0", width: "100%", height: "100%", boxShadow: "none", margin: "0" }}>
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton height={60} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={250} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={60} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={100} animation="wave" variant="rectangular" style={{ marginTop: "30px" }} />
                    </Card>
                )
            }
            {
                login && (
                    <Card style={{ boxShadow: "none", margin: "15px" }}>
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={60} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton height={100} animation="wave" variant="rectangular" style={{ marginTop: "300px" }} />
                        <Skeleton animation="wave" height={60} width="100%" style={{ marginBottom: 6 }} />
                    </Card>
                )
            }
            {
                signUp && (
                    <Card style={{ boxShadow: "none", margin: "15px" }}>
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={60} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton height={200} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton height={100} animation="wave" variant="rectangular" style={{ marginTop: "10px" }} />
                        <Skeleton animation="wave" height={60} width="100%" style={{ marginBottom: 6 }} />
                    </Card>
                )
            }
            {
                getToken && (
                    <Card style={{ boxShadow: "none", margin: "15px" }}>
                        <Skeleton height={100} animation="wave" width="100%" variant="rectangular" style={{ marginTop: "300px" }} />
                    </Card>
                )
            }
            {
                signUpFirst && (
                    <Card style={{ boxShadow: "none", margin: "15px" }}>
                        <Skeleton height={50} animation="wave" width="100%" variant="rectangular" style={{ marginTop: "300px" }} />
                        <Skeleton height={30} animation="wave" width="100%" variant="rectangular" style={{ marginTop: "20px" }} />
                        <Grid container spacing={3} justifyContent="space-around" alignItems="center" alignContent="space-between" style={{ marginTop: "20px" }}>
                            <Skeleton height={50} width="30%" animation="wave" variant="rectangular" />
                            <Skeleton height={50} width="30%" animation="wave" variant="rectangular" />
                        </Grid>
                    </Card>
                )
            }
            {
                (signUpOption || loginOpt) && (
                    <Card style={{ boxShadow: "none", margin: "15px", height: "100vh" }}>
                        <Skeleton height={30} animation="wave" width="100%" variant="rectangular" style={{ marginTop: "5px" }} />
                        <Skeleton height={70} animation="wave" width="100%" variant="rectangular" style={{ marginTop: "30px" }} />
                        <Skeleton height={40} animation="wave" width="100%" variant="rectangular" style={{ marginTop: "40px", borderRadius: "10px" }} />
                        <Skeleton height={40} animation="wave" width="100%" variant="rectangular" style={{ marginTop: "20px", borderRadius: "10px" }} />
                        <Skeleton height={40} animation="wave" width="100%" variant="rectangular" style={{ marginTop: "20px", borderRadius: "10px" }} />
                        <Skeleton height={40} animation="wave" width="100%" variant="rectangular" style={{ marginTop: "20px", borderRadius: "10px" }} />
                        <Skeleton height={40} animation="wave" width="100%" variant="rectangular" style={{ marginTop: "20px", borderRadius: "10px" }} />
                    </Card>
                )
            }
            {
                delivery && (
                    <Card style={{ boxShadow: "none", margin: "15px" }}>
                        <Skeleton animation="wave" height={60} width="100%" style={{ marginTop: 20 }} />
                        <Skeleton animation="wave" height={100} width="100%" style={{ marginBottom: 12 }} />
                        <Skeleton animation="wave" height={60} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={60} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={60} width="100%" style={{ marginBottom: 6 }} />
                    </Card>
                )
            }
            {
                appbar && (
                    <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mx: 2, mt: 1 }}>
                        <Skeleton animation="wave" height={60} width={38} />
                        <Skeleton animation="wave" height={60} width={128} />
                        <Skeleton animation="wave" height={60} width={38} />
                        <Skeleton animation="wave" height={60} width={16} />
                        <Skeleton animation="wave" height={60} width={38} />
                    </Stack>
                )
            }
        </Box>
    )
}

export default SkeletonLoading