import { initializeApp } from 'firebase/app';
import {
    getRedirectResult,
    signInWithRedirect,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    signInWithEmailAndPassword,
    signInWithPopup,
    getAuth,
} from "firebase/auth";
import {
    doc,
    addDoc,
    CACHE_SIZE_UNLIMITED,
    DocumentData,
    enableIndexedDbPersistence,
    getFirestore,
    initializeFirestore,
    onSnapshot,
    query,
    QueryConstraint,
    updateDoc,
    where,
    WhereFilterOp,
    collection,
    getDoc,
    getDocs,
    setDoc,
} from "firebase/firestore";
import { getAnalytics, logEvent, isSupported } from "firebase/analytics";
import "firebase/auth";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, confirmPasswordReset } from "firebase/auth";
import * as Constants from './Constants';
import * as _ from 'lodash';
import moment from 'moment';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
    authDomain: "order-and-eat-now.firebaseapp.com",
    projectId: "order-and-eat-now",
    storageBucket: "order-and-eat-now.appspot.com",
    messagingSenderId: "156000405224",
    appId: process.env.REACT_APP_FIREBASE_APP_ID ?? "",
    measurementId: "G-5WE16XBFTS"
};

const app = initializeApp(firebaseConfig);

initializeFirestore(app, {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
});

export const db = getFirestore(app);

export const getInstanceDb = () => db

enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
});

export const auth = getAuth();

export const logout = () => getAuth().signOut()

export async function queryFirebase(collectionName: string) {
    await firebaseSignIn();
    let queryCollection = collection(db, collectionName);
    return await getDocs(queryCollection);
}


export async function queryDoc(collectionName: string, documentName: string) {
    await firebaseSignIn();
    let queryCollection = doc(db, collectionName, documentName);
    return await getDoc(queryCollection);
}

export async function queryData(collectionName: string) {
    let queryCollection = collection(db, collectionName)
    return await getDocs(queryCollection)
}

export async function saveDoc(collectionName: string, documentName: string, data: any) {
    await firebaseSignIn();
    await setDoc(doc(db, collectionName, documentName), data, { merge: true });
}

export async function addDocs(collectionName: string, data: any) {
    await firebaseSignIn();
    let queryCollection = collection(db, collectionName)
    await addDoc(queryCollection, data);
}

export async function updateData(collection: string, document: string, collectionObject: any) {
    await firebaseSignIn();
    const docRef = doc(db, collection, document);
    await updateDoc(docRef, collectionObject);
}

export async function deleteFields(collectionName: string, documentName: string, data: any) {
    await firebaseSignIn();
    await updateDoc(doc(db, collectionName, documentName), data, { merge: true });
}

export async function sendEvents(eventName: string, event: any) {
    if (await isSupported()) {
        const analytics = getAnalytics();
        logEvent(analytics, eventName, event);
    }
}

export async function signInUsingPhoneNumber(phoneNumber: string, appVerifier: any) {
    try {
        return await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
    } catch (error) {
        sendEvents("errorSignInUsingPhoneNumber", { error });
    }
}

export function setPhoneVerification(containerOrId: HTMLElement | string, parameters: any) {
    try {
        return new RecaptchaVerifier(containerOrId, parameters, auth);
    } catch (error) {
        sendEvents("errorSetPhoneVerification", { error });
    }
}

async function firebaseSignIn() {
    const currentUser = auth.currentUser;
    if (!currentUser) {
        const firebasePassword = process.env.REACT_APP_FIREBASE_PASSWORD ?? "";
        const firebaseUser = process.env.REACT_APP_FIREBASE_USER ?? "";
        await signInWithEmailAndPassword(auth, firebaseUser, firebasePassword);
    }
}
export const createUserEmailAndPassword = async (email: string, password: string) => await createUserWithEmailAndPassword(auth, email, password)

export const SignUpWithSocialMedia = async (provider: any) => await signInWithPopup(auth, provider)

export const PasswordReset = async (email: string) => await sendPasswordResetEmail(auth, email)

export const confirmPasswordchange = async (oobCode: any, password: string) => await confirmPasswordReset(auth, oobCode, password)

export const queryOrders = async (collectionName: string, state: any[]) => {
    if (localStorage.getItem("idEmail") ?? "") {
        let queryCollection = query(collection(db, collectionName), where("state", "in", state), where("uidEmail", "==", localStorage.getItem("idEmail")))
        return await getDocs(queryCollection);
    }
    if (localStorage.getItem("idPhone") ?? "") {
        let queryCollection = query(collection(db, collectionName), where("state", "in", state), where("uidPhone", "==", localStorage.getItem("idPhone")))
        return await getDocs(queryCollection);
    }
    if (localStorage.getItem("idFacebook") ?? "") {
        let queryCollection = query(collection(db, collectionName), where("state", "in", state), where("uidFacebook", "==", localStorage.getItem("idFacebook")))
        return await getDocs(queryCollection);
    }
    if (localStorage.getItem("idTwitter") ?? "") {
        let queryCollection = query(collection(db, collectionName), where("state", "in", state), where("uidTwitter", "==", localStorage.getItem("idTwitter")))
        return await getDocs(queryCollection);
    }
    if (localStorage.getItem("idGoogle") ?? "") {
        let queryCollection = query(collection(db, collectionName), where("state", "in", state), where("uidGoogle", "==", localStorage.getItem("idGoogle")))
        return await getDocs(queryCollection);
    }

}

export const getDataLocal = async (collectionName: string, page: string) => {
    await firebaseSignIn();
    const q = query(collection(db, collectionName));
    const language: any = await new Promise(function (resolve, reject) {
        let object: object[] = []
        onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                let data = change.doc.data()
                //console.log(change.doc.id + ": ", data);
                //const source = snapshot.metadata.fromCache ? "local cache" : "server";
                //console.log(change.doc.id + " => " + source);
                if (page && change.doc.id === page) {
                    object.push(data)
                }
                if (!page) {
                    object.push(data)
                }
            });
            resolve(object)
        });

    })
    return language;
}

export const SignUpWithSocial = async (provider: any) => {
    const signUpSocial: any = await new Promise(function (resolve, reject) {
        const authSocial = signInWithRedirect(auth, provider)
        resolve(authSocial)
    })
    return signUpSocial
}

export const GetDataSocial = async (social: string, deliveryMan: boolean) => {
    const getDataSocial: any = await new Promise(function (resolve, reject) {
        let response = { state: false, uid: "", idDynamic: "", error: "" }
        getRedirectResult(auth)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access Google APIs.
                if (result) {
                    const key = "id" + social
                    let idDynamic = await LoginMethod(key, result.user.uid, deliveryMan)
                    if (!idDynamic) {
                        let timeStamp = new Date();
                        idDynamic = `${timeStamp.getMonth() + 1}${timeStamp.getDate()}${timeStamp.getHours()}${timeStamp.getMinutes()}${timeStamp.getSeconds()}${timeStamp.getMilliseconds()}`;
                        const newUser = {
                            [idDynamic]: {
                                [key]: result.user.uid,
                                email: result.user.email,
                                name: result.user.displayName
                            }
                        }
                        await saveDoc("users", deliveryMan ? 'deliveryMan' : 'Customer', newUser);
                        response.idDynamic = idDynamic
                    } else {
                        response.idDynamic = idDynamic
                    }
                    localStorage.setItem("idEmail", result.user.uid);
                    localStorage.setItem(key, result.user.uid);
                    localStorage.setItem("fullName", result.user.displayName ?? "");
                    const idOrder = localStorage.getItem("idOrders") ?? ""
                    if (idOrder) {
                        const uidKey = "u" + key
                        const setUserOrder = {
                            [uidKey]: result.user.uid
                        }
                        const restaurantName = localStorage.getItem(Constants.restaurantName) ?? "";
                        await saveDoc(restaurantName + "/orders/orders", idOrder, setUserOrder);
                    }
                    response.state = true
                    response.uid = result.user.uid
                    resolve(response)
                }
            }).catch((error) => {
                // Handle Errors here.
                response.error = error
                resolve(response)
            });
    })
    return getDataSocial
}

export const LoginMethod = async (key: string, uid: string, deliveryMan: boolean) => {
    const users = await queryDoc("users", deliveryMan ? 'deliveryMan' : 'Customer');
    let idDynamicDb: string = ""
    if (users.exists()) {
        const usersData = users.data()
        Object.entries(usersData).forEach(([idDynamic, data]) => {
            if (data[key] === uid) {
                idDynamicDb = idDynamic
                if (key === "idEmail") {
                    localStorage.setItem("idEmail", uid);
                }
                if (key === "idMobile") {
                    localStorage.setItem("idPhone", uid);
                }
                if (key === "idFacebook") {
                    localStorage.setItem("idFacebook", uid);
                }
                if (key === "idTwitter") {
                    localStorage.setItem("idTwitter", uid);
                }
                if (key === "idGoogle") {
                    localStorage.setItem("idGoogle", uid);
                }
            }
        });
    }
    return idDynamicDb
}

export const queryDiscountsLocal = async (discounts: string, restaurantName: string) => {
    await firebaseSignIn();
    const q = query(collection(db, `${restaurantName}/discounts/discounts`), (where('discountSecret', '==', discounts)), (where('active', '==', true)));
    const data: any = await new Promise(function (resolve, reject) {
        const arrayData: { data: DocumentData; id: string }[] = [];
        onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                arrayData.push({
                    data: change.doc.data(),
                    id: change.doc.id
                })
            });
            resolve(arrayData)
        });

    })
    return data;
}

export const queryRegularDiscountsLocal = async (restaurantName: string, currentDay: string) => {
    await firebaseSignIn();
    const q = query(collection(db, `${restaurantName}/discounts/regularDiscounts`), (where('active', '==', true)), (where('day', '==', currentDay)));
    const data: any = await new Promise(function (resolve, reject) {
        const arrayData: { data: DocumentData; id: string }[] = [];
        onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                arrayData.push({
                    data: change.doc.data(),
                    id: change.doc.id
                })
            });
            resolve(arrayData)
        });

    })
    return data;
}

export const deleteSuccessfulDiscount = async (idDiscount: string, restaurantName: string) => {
    //we deduct the discount amount of discount or coupon that was used and the payment was made
    const docRef = doc(db, `${restaurantName}/discounts/discounts`, idDiscount);

    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const a = docSnap.data()
        if (a.amountOfDiscounts > 0) {
            const newAmountOfDiscounts = a.amountOfDiscounts - 1
            await updateDoc(docRef, {
                amountOfDiscounts: newAmountOfDiscounts
            });
        } else {
            return
        }
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
    // await updateDoc(docRef, {
    //     amountOfDiscounts: deleteField()
    // });
}

//query to bring from bd,the scheduled agenda for the hotel
export const queryToRoomsAvailable = async (
    restaurantName: string,
    numberOfPerson: number,
    dateInicial: string,
    dateFinal: string,
    listRooms: any[]
) => {
    let roomOccupancy: any[] = []
    let numberofdays: number = 1
    let arrayIdRooms: any[] = []
    let arrayReservations: any[] = []
    const startDateIn = moment(dateInicial)
    const endDateIn = moment(dateFinal)
    // 1 seleccionar de calendar los datos en las fechas seleccionadas
    const q2 = query(collection(db, `/${restaurantName}/reservations/reservations`), where("startDate", ">=", dateInicial));
    const querySnapshot2 = await getDocs(q2);
    querySnapshot2.forEach((doc) => {
        const data = doc.data()
        const startDateDb = moment(data.startDate)
        const endDateDb = moment(data.endDate)
        if (startDateIn <= endDateDb && endDateIn >= startDateDb) {
            arrayReservations.push(data)
        }
    })
    listRooms.forEach((room) => {
        let findReservation = arrayReservations.filter(reservation => reservation.idRoom === room.id)
        let limitGuests = false
        if (findReservation && findReservation.length > 0) {
            if (room.Hostel) { // Si es un hostal
                let guests = 0
                let limitNumberOfGuests = room.numberSimilarRooms * room.NumberOfGuests // se calcula el limite de personas
                findReservation.forEach((find) => {
                    guests += find.guests
                })
                if ((guests + numberOfPerson) > limitNumberOfGuests) {
                    limitGuests = true
                    arrayIdRooms.push(room.id) // agrega el id del room para que no se muestre
                } else {
                    roomOccupancy.push({
                        idRoom: room.id,
                        occupancy: limitNumberOfGuests - guests
                    })
                }
            } else {
                if (room.numberSimilarRooms > 1) {// si es mayor a 1 significa que tiene cuartos similares
                    if (findReservation.length === room.numberSimilarRooms) {// Verifica si todos los cuartos similares están reservados
                        limitGuests = true
                        arrayIdRooms.push(room.id) // agrega el id del room para que no se muestre
                    }
                } else {
                    limitGuests = true
                    arrayIdRooms.push(room.id) // agrega el id del room para que no se muestre
                }
            }
        }
        if (!limitGuests && room.NumberOfGuests < numberOfPerson) {
            arrayIdRooms.push(room.id) // agrega el id del room para que no se muestre
        }
    })
    const listIdsRoomsavailable = arrayIdRooms.filter((idRoom, index) => {
        return arrayIdRooms.indexOf(idRoom) === index;
    });
    let f1 = moment(dateInicial)
    let f2 = moment(dateFinal)
    numberofdays = f2.diff(f1, 'days')
    return { listIdsRoomsavailable, numberofdays, roomOccupancy }

}

//query to bring from bd,the scheduled agenda for the hotel
export const queryToScheduleAgenda = async (
    restaurantName: string,
    documentName: string,
    collectionName: string,
    numberOfPerson: number,
    dateInicial: string,
    dateFinal: string
) => {
    let arrayRoomsdata: any[] = []
    let arrayRoomsdataObj: any[] = []
    let listIdsRoomsavailable: string[] = []
    let idCalendarData: any[] = []
    let roomOccupancy: any[] = []
    let similarRoomsReamining: any[] = []
    let numberofdays: number = 1
    // 1 seleccionar de calendar los datos en las fechas seleccionadas
    const q = query(collection(db, `/${restaurantName}/${documentName}/${collectionName}`),
        where("date", ">=", dateInicial),
        where("date", "<=", dateFinal),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        const data = doc.data()
        const id = doc.id
        if (data.Guess >= numberOfPerson) {
            // console.log('id agendas',doc.id)
            // console.log('id agendas',doc.data())
            // console.log('id Hostel',doc.data().idsHostel)
            idCalendarData.push(
                {
                    id: id,
                    data: data
                }
            )
            arrayRoomsdata = [...arrayRoomsdata, data]
            // se crea aaray de obj con todos los ids de los cuarts q cumplan las condiciones
            data.ids.forEach(({ id, reaminingRoomSimilars }: { id: string, reaminingRoomSimilars: string }) => {
                arrayRoomsdataObj.push({
                    numberId: id
                })
                similarRoomsReamining.push({
                    idRoom: id,
                    availabilitySimilarRooms: reaminingRoomSimilars
                })
            })
            //si es un hostal quiere decir que el array idsHostel tiene data entonces se agregan a el array de objetos de los room
            if (data.hasOwnProperty('idsHostel')) {
                data.idsHostel.forEach(({ id, remaining }: any) => {
                    if (remaining >= numberOfPerson) {// Si el restante es mayor o igual a la cantidad ee personas, muestraesat room en la busqueda
                        arrayRoomsdataObj.push({
                            numberId: id
                        })
                        roomOccupancy.push({
                            idRoom: id,
                            occupancy: remaining
                        })
                    }
                })
            }
        }
    });
    // console.log("1.arrayRoomsdataObj",arrayRoomsdataObj);
    // console.log("1.arrayRoomsdata",arrayRoomsdata);
    // console.log("1.occupancy",roomOccupancy);
    // console.log("2.occupancy",similarRoomsReamining);
    //se agrupa por ids para verificar el .lenght y saber cuantos se repiten
    const groupByIdRoom = _.groupBy(arrayRoomsdataObj, room => room.numberId);
    //console.log("2.group",groupByIdRoom)
    // console.log("numberofdays",arrayRoomsdata.length);
    let f1 = moment(dateInicial)
    let f2 = moment(dateFinal)
    numberofdays = f2.diff(f1, 'days')
    // console.log("numberofdays",numberofdays);
    // console.log("arrayRoomsdata.length",arrayRoomsdata.length);

    // numberofdays = arrayRoomsdata.length
    for (const property in groupByIdRoom) { // se recorre el obj agrupado y se guardan solomlos arrays q cumplan con la condicion .length === numberofdays
        if (groupByIdRoom[property].length === (numberofdays + 1)) {
            listIdsRoomsavailable.push(property)
        }
    }

    // console.log("listIdsRoomsavailable",listIdsRoomsavailable)
    return { listIdsRoomsavailable, idCalendarData, numberofdays, roomOccupancy, similarRoomsReamining }

}
// function update status booking and value advanced
export const updateStatusBooking = async (restaurantName: string, idBooking: string, updateStatus: boolean, status: string, valueAdvanced: number = 0) => {
    const docRef = doc(db, `${restaurantName}/reservations/reservations`, idBooking);
    if (updateStatus) await updateDoc(docRef, {
        status: status
    });

    if (!updateStatus) await updateDoc(docRef, {
        valuePaid: valueAdvanced
    });

}

//function to bring previous bookings by user
export const queryBookingsPrevious = async (collectionName: string) => {
    if (localStorage.getItem("idEmail") ?? "") {
        let queryBookings = query(collection(db, collectionName), where("uidEmail", "==", localStorage.getItem("idEmail")))
        return await getDocs(queryBookings);
    }
}

//query bookings by id or by name
export async function queryBookingsByIdorByName(restaurantName: string, name: string) {
    let criterioBusuqeda = name.toLowerCase()
    //console.log("criterioBusuqeda", criterioBusuqeda)
    let dataArray: any[] = []
    const orders = query(collection(db, `/${restaurantName}/reservations/reservations`))
    const querySnapshot = await getDocs(orders);
    querySnapshot.forEach((doc) => {
        if (doc.data().hasOwnProperty('fullname')) {
            const { date, status, fullname, urlImage, totalPrice, idReserve } = doc.data()
            const fullNameMinus = fullname.toLowerCase()
            //const arrayName = fullNameMinus.split(" ")
            if (fullNameMinus.includes(criterioBusuqeda)) {
                //console.log(fullNameMinus)
                //console.log(arrayName)
                dataArray.push({
                    fullname,
                    status,
                    urlImage,
                    date: date.split(' ')[0],
                    totalPrice,
                    id: idReserve
                })
            }

        }
    })
    // console.log("dataArray",dataArray)

    return dataArray
}
export const getWaiterOrder = async (collectionName: string, tableNumber: string) => {
    let queryCollection = query(collection(db, collectionName),
        where("state", "in", ["ordered", "pending-payment"]),
        where("tableNumber", "==", tableNumber),
        where("isPaid", "==", false),
        where("isWaiterOrder", "==", true))
    return await getDocs(queryCollection);
}

//update Status and color for each table 
export async function changeStatusAndColorTable(
    collectionName: string, documentName: string, refTable: string, idTable: string, status: string, color: string, state: string, occupationDate: string, releaseDate: string) {
    const ref = doc(db, collectionName, documentName);
    const referTableStatus = `${refTable}.${idTable}.status`
    const referTableColor = `${refTable}.${idTable}.color`
    const referOrderStatus = `${refTable}.${idTable}.orderStatus`
    const referTableOccupationDate = `${refTable}.${idTable}.occupationDate`
    const referReleaseDate = `${refTable}.${idTable}.releaseDate`
    await updateDoc(ref, {
        [referTableStatus]: status,
        [referTableColor]: color,
        [referOrderStatus]: state,
        [referTableOccupationDate]: occupationDate,
        [referReleaseDate]: releaseDate,
    });
}
//update Status table
export async function changeStatusTable(
    collectionName: string, documentName: string, refTable: string, idTable: string, state: string) {
    const ref = doc(db, collectionName, documentName);
    const referOrderStatus = `${refTable}.${idTable}.orderStatus`
    await updateDoc(ref, {
        [referOrderStatus]: state,
    });
}
export const getReservationProducts = async (collectionName: string, startDate: string, endDate: string, limit: number) => {
    await firebaseSignIn();
    const currentDate = new Date()
    const dateStr = moment(currentDate).format('YYYY-MM-DD HH:mm:ss')
    const startDateIn = moment(startDate)
    const endDateIn = moment(endDate)
    const q = query(collection(db, collectionName),
        where("startDate", ">=", dateStr),
        where("status", "in", ['fully paid', 'partial payment']));
    const data: any = await new Promise(function (resolve, reject) {
        const arrayData: { data: DocumentData; id: string }[] = [];
        onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                const doc = change.doc.data()
                const startDateDb = moment(doc.startDate)
                const endDateDb = moment(doc.endDate)
                if (startDateIn <= endDateDb && endDateIn >= startDateDb) {
                    arrayData.push(doc.idProduct)
                }
            });
            resolve(arrayData)
        });
    })
    const limitMin: number = limit ? limit : 0
    currentDate.setMinutes(currentDate.getMinutes() - limitMin)
    const dateStr1 = moment(currentDate).format('YYYY-MM-DD HH:mm:ss')
    const q2 = query(collection(db, collectionName),
        where("date", ">=", dateStr1),
        where("status", "==", 'pending payment'));
    const dateLocal = new Date()
    const finTime = moment(dateLocal)
    const data2: any = await new Promise(function (resolve, reject) {
        const arrayData: { data: DocumentData; id: string }[] = [];
        onSnapshot(q2, { includeMetadataChanges: true }, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                const dateDb = moment(change.doc.data().date)
                if (dateDb < finTime) {
                    arrayData.push(change.doc.data().idProduct)
                }
            });
            resolve(arrayData)
        });

    })
    return data.concat(data2);
}

export const getNotReservationProducts = async (collectionName: string, idProducts: string[]) => {
    let q = query(collection(db, collectionName))
    if (idProducts.length > 0) {
        q = query(collection(db, collectionName), where("id", "not-in", idProducts));
    }
    const data: any = await new Promise(function (resolve, reject) {
        const arrayData: { data: DocumentData; id: string }[] = [];
        onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                arrayData.push({
                    data: change.doc.data(),
                    id: change.doc.id
                })
            });
            resolve(arrayData)
        });

    })
    return data;
}

export const getReservationToReview = async (collectionName: string, idReservation: string) => {
    let q = query(collection(db, collectionName), where("idReservation", "==", idReservation));
    const data: any = await new Promise(function (resolve, reject) {
        let setData: DocumentData = {};
        onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                setData = change.doc.data()
            });
            resolve(setData)
        });

    })
    return data;
}

export const getProductById = async (collectionName: string, idProduct: string) => {
    let q = query(collection(db, collectionName), where("id", "==", idProduct));
    const data: any = await new Promise(function (resolve, reject) {
        let setData: DocumentData = {};
        onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                setData = change.doc.data()
            });
            resolve(setData)
        });

    })
    return data;
}

export const getReviews = async (collectionName: string, key: string, value: string) => {
    let q = query(collection(db, collectionName), where("isReview", "==", true));
    if (key && value) {
        q = query(collection(db, collectionName), where("isReview", "==", true), where(key, "==", value));
    }
    const data: any = await new Promise(function (resolve, reject) {
        let setData: DocumentData[] = [];
        onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                setData.push(change.doc.data())
            });
            resolve(setData)
        });

    })
    return data;
}
export const getHolidays = async (dateInicial: string, dateFinal: string, country: any, restaurantName: string) => {

    let listIdsEventHolidaysPortal: any[] = []
    let listIdsEventHolidaysRestaurant: any[] = []

    const q = query(collection(db, `/portalUsers/EventCalendar/${country.country}`));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        if (doc.data().startDate >= dateInicial && doc.data().endDate <= dateFinal) {
            listIdsEventHolidaysPortal.push(doc.data())
        }
    })

    const q2 = query(collection(db, `/${restaurantName}/EventCalendar/${country.country}`));
    const querySnapshotTwo = await getDocs(q2);
    querySnapshotTwo.forEach((doc) => {
        if (doc.data().startDate >= dateInicial && doc.data().endDate <= dateFinal) {
            listIdsEventHolidaysRestaurant.push(doc.data())
        }
    })

    return { listIdsEventHolidaysPortal, listIdsEventHolidaysRestaurant }
}

export const getNameRelationShip = async (restaurant: string, redirect: string) => {
    let arrayRelationShip = []
    const restNameLocalStorage = localStorage.getItem('restaurantName')
    const firebaseQuery = await getDataLocal(restaurant, 'relationship');
    const relationList = firebaseQuery ? firebaseQuery[0] : null
    if (relationList) {
        let name: string = restaurant
        let path: string = redirect
        arrayRelationShip = relationList.joinRestaurants
        arrayRelationShip.unshift(restNameLocalStorage);
        // console.log('arrayRelationShip :>> ', arrayRelationShip);
        // switch (redirect) {
        //     case 'hotelLogo':
        //         path = 'hotelLogo'
        //         break;

        //     default:
        //         break;
        // }
        for (let index = 0; index < arrayRelationShip.length; index++) {
            const element = arrayRelationShip[index];
            const docData = await queryDoc(element, path);
            const data = docData?.data();
            if (data) {
                name = element
                break
            }
        }
        return name
    }
}

//function to bring previous bookings by user
export const querySearchOrder = async (collectionName: string, value: string) => {
    let searchOrder = query(collection(db, collectionName), where("tableNumber", "==", value))
    const data = await getDocs(searchOrder);
    let idOrders = ""
    data?.forEach((doc) => {
        const { tableNumber } = doc.data()
        idOrders = doc.id
        localStorage.setItem('tableNumber', tableNumber)

    })
    if (!idOrders) {
        const docRef = doc(db, collectionName, value);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const { tableNumber } = docSnap.data()

            idOrders = docSnap.id
            localStorage.setItem('tableNumber', tableNumber)
        }
    }
    return idOrders
}

export const queryDynamic = async (collectionName: string, arrayConditions: IFirestoreQuery[]) => {
    await firebaseSignIn();
    const filter: QueryConstraint[] = arrayConditions.map((condition) =>
        where(condition.property, condition.operator, condition.value)
    )
    const q = query(collection(db, collectionName), ...filter);
    const data: any = await new Promise(function (resolve, reject) {
        const arrayData: { data: DocumentData; id: string }[] = [];
        onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                arrayData.push({
                    data: change.doc.data(),
                    id: change.doc.id
                })
            });
            resolve(arrayData)
        });

    })
    return data;
}

export const querySearchOrderAvaliable = async (collectionName: string, idDeliveryMan: string) => {
    // const filterState = ['paid', 'prepared', 'pending-payment-delivery', 'pending-payment']
    let searchOrder = query(collection(db, collectionName), where("idDeliveryDriver", "==", idDeliveryMan), where("stateDelivery", "!=", 'delivered'))
    // query(collection(db, collectionName), where("tableNumber", "==", 'delivery'), where("state", "in", filterState))
    const data = await getDocs(searchOrder);
    let arrayData: any[] = []
    data?.forEach((doc) => {
        arrayData.push({
            data: doc.data(),
            id: doc.id
        })
    })
    return arrayData
}

export interface IFirestoreQuery {
    property: string;
    operator: WhereFilterOp;
    value: any;
}
