import { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as RoutesApp from "./common/Routes";
import Overlay from "./components/Overlay/Overlay"
// import { queryDoc } from './common/FirebaseHelper';
import SkeletonLoading from './components/Skeleton/SkeletonLoading';
import { logout } from './common/FirebaseHelper';

const Maps = lazy(() => import("./components/Maps/Maps"));
const Menu = lazy(() => import("./components/Menu/Menu"));
const Welcome = lazy(() => import("./components/Welcome/Welcome"));
const Reviews = lazy(() => import("./components/Reviews/Reviews"));
const ReviewsDetails = lazy(() => import("./components/Reviews/Details"));
const PageNotFound = lazy(() => import("./components/PageNotFound"));
const Home = lazy(() => import("./components/Home"));
const Cart = lazy(() => import("./components/Cart/Cart"));
const SignUpFirstUse = lazy(() => import("./components/SignUpFirstUse/SignUpFirstUse"));
const PayCash = lazy(() => import("./components/PayCash/PayCash"));
const EditMenu = lazy(() => import("./components/EditMenu/EditMenu"));
const SubMenu = lazy(() => import("./components/SubMenu/SubMenu"));
const SignUp = lazy(() => import("./components/SignUp/SignUpOption"));
const SignUpPhoneNumber = lazy(() => import("./components/SignUp/SignUpPhoneNumber"));
const Profile = lazy(() => import("./components/Profile/Profile"));
const MyInformation = lazy(() => import("./components/Profile/MyInformation"));
const Login = lazy(() => import("./components/Login/LoginOption"));
const ContactUs = lazy(() => import("./components/ContactUs/ContactUs"));
const PendingPay = lazy(() => import("./components/PendingPay/PendingPay"));
const PreviousOrder = lazy(() => import("./components/Cart/PreviousOrder"));
const ForgotPassword = lazy(() => import("./components/Login/ForgotPassword"));
const ChangePassword = lazy(() => import("./components/Login/ChangePassword"));
const GetToken = lazy(() => import("./components/Stripe/GetToken"));
const Successful = lazy(() => import("./components/Stripe/Successful"));
const UnSuccessful = lazy(() => import("./components/Stripe/UnSuccessful"));
const PickUp = lazy(() => import("./components/PickUp/PickUp"));
const Notification = lazy(() => import("./components/Notification/Notification"));
const Reservation = lazy(() => import("./components/Maps/Reservation"));
const VirtualQueue = lazy(() => import("./components/Maps/VirtualQueue"));
const OurService = lazy(() => import("./components/Maps/OurService"));
const Delivery = lazy(() => import("./components/Delivery/Delivery"));
const Tracking = lazy(() => import("./components/Tracking/Tracking"));
const SplitPay = lazy(() => import("./components/SplitPay"))
const SplitEvenly = lazy(() => import("./components/SplitPay/SplitEvenly"))
const SplitSelect = lazy(() => import("./components/SplitPay/SplitSelect"))
const GetTokenSelect = lazy(() => import('./components/SplitPay/SplitSelect/GetTokenSelect'))
const SuccefullSelect = lazy(() => import('./components/SplitPay/SplitSelect/SuccefullSelect'))
const HotelReservations = lazy(() => import("./components/Reservation/CalendarReservations"));
const SuccessfulReservation = lazy(() => import("./components/Reservation/Success"));
const UnSuccessfulReservation = lazy(() => import("./components/Reservation/Unsuccessful"));
const SearchBooking = lazy(() => import("./components/Reservation/SearchBooking"));
const Pages = lazy(() => import("./components/Pages/Index"));
const Services = lazy(() => import("./components/Services"))
const GoogleMaps = lazy(() => import("./components/GoogleMaps"))
const DeliveryMan = lazy(() => import("./components/DeliveryMan"))
const PayByCode = lazy(() => import("./components/PayByCode"))

const AppRouter = () => {

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const loadPages = () => {
      fetch(`${process.env.PUBLIC_URL}/version.json`)
        .then((r) => r.json())
        .then((data) => {
          console.log(`Version ${data.version}`);
          let currentVersion = localStorage.getItem("version_app") ?? "";
          let newVersion = data?.version ?? "";
          if (currentVersion !== newVersion) {
            console.log(`new version ${newVersion}`);
            localStorage.setItem("version_app", newVersion);
            let objectLocation: any = window.location;
            caches.keys().then(function (names) {
              for (let name of names) {
                console.log(`${name}`);
                caches.delete(name);
                objectLocation.reload(true);
              }
            })
            logout()
          }
          setLoading(false)
        })
    }
    loadPages()
  }, [])

  return !loading ? (
    <Router>
      <Suspense fallback={<Overlay openOverlay={true} />}>
        <Routes>
          <Route path={RoutesApp.Default} element={<Maps />} />
          <Route path={RoutesApp.Home} element={<Home />} />
          <Route path={RoutesApp.Root} element={<Welcome />} />
          <Route path={RoutesApp.MenuEdit} element={<EditMenu />} />
          <Route path={RoutesApp.Menu} element={<Menu />} />
          <Route path={RoutesApp.SubMenu} element={<SubMenu />} />
          <Route path={RoutesApp.SignUp} element={<SignUp />} />
          <Route path={RoutesApp.SignUpWithPhoneNumber} element={<SignUpPhoneNumber />} />
          <Route path={RoutesApp.PageNotFound} element={<PageNotFound />} />
          <Route path={RoutesApp.Profile} element={<Profile />} />
          <Route path={RoutesApp.MyInformation} element={<MyInformation />} />
          <Route path={RoutesApp.Login} element={<Login />} />
          <Route path={RoutesApp.Cart} element={<Cart />} />
          <Route path={RoutesApp.SignUpFirstUse} element={<SignUpFirstUse />} />
          <Route path={RoutesApp.PayCash} element={<PayCash />} />
          <Route path={RoutesApp.SubMenu} element={<SubMenu />} />
          <Route path={RoutesApp.Contact} element={<ContactUs />} />
          <Route path={RoutesApp.PendingPay} element={<PendingPay />} />
          <Route path={RoutesApp.previousOrder} element={<PreviousOrder />} />
          <Route path={RoutesApp.forgotPassword} element={<ForgotPassword />} />
          <Route path={RoutesApp.Changepassword} element={<ChangePassword />} />
          <Route path={RoutesApp.GetToken} element={<GetToken />} />
          <Route path={RoutesApp.SuccessfulStripe} element={<Successful />} />
          <Route path={RoutesApp.SuccessfulePayco} element={<Successful />} />
          <Route path={RoutesApp.UnSuccessfulStripe} element={<UnSuccessful />} />
          <Route path={RoutesApp.PickUp} element={<PickUp />} />
          <Route path={RoutesApp.Reservation} element={<Reservation />} />
          <Route path={RoutesApp.VirtualQueue} element={<VirtualQueue />} />
          <Route path={RoutesApp.OurService} element={<OurService />} />
          <Route path={RoutesApp.Delivery} element={<Delivery />} />
          <Route path={RoutesApp.Tracking} element={<Tracking />} />
          <Route path={RoutesApp.SplitPay} element={<SplitPay />} />
          <Route path={RoutesApp.SplitEvely} element={<SplitEvenly />} />
          <Route path={RoutesApp.SplitEvelyPay} element={<GetToken />} />
          <Route path={RoutesApp.UnSuccessfulStripeSplit} element={<UnSuccessful />} />
          <Route path={RoutesApp.SplitSelect} element={<SplitSelect />} />
          <Route path={RoutesApp.GetTokenSelect} element={<GetTokenSelect />} />
          <Route path={RoutesApp.SuccefullSelect} element={<SuccefullSelect />} />
          <Route path={RoutesApp.HotelReservations} element={<HotelReservations />} />
          <Route path={RoutesApp.SuccessfulStripeReservation} element={<SuccessfulReservation />} />
          <Route path={RoutesApp.UnSuccessfulStripeReservation} element={<UnSuccessfulReservation />} />
          <Route path={RoutesApp.SearchBooking} element={<SearchBooking />} />
          <Route path={RoutesApp.Pages} element={<Pages />} />
          <Route path={RoutesApp.Reviews} element={<Reviews />} />
          <Route path={RoutesApp.ReviewsDetails} element={<ReviewsDetails />} />
          <Route path={RoutesApp.Services} element={<Services />} />
          <Route path={RoutesApp.GoogleMaps} element={<GoogleMaps />} />
          <Route path={RoutesApp.DeliveryMan} element={<DeliveryMan />} />
          <Route path={RoutesApp.PayByCode} element={<PayByCode />} />
        </Routes>
        <Notification />
      </Suspense>
    </Router>
  ) : (
    <SkeletonLoading getToken={true} />
  )
};

export default AppRouter;
