import { makeStyles } from 'tss-react/mui';
import { Box, Typography, CircularProgress } from '@mui/material'

const useStyles = makeStyles()(() =>
({
    overlayStyle: {
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "rgba(0,0,0,0.1)",
        backdropFilter: "blur(1.4px)",
        zIndex: 2000,
    },
    line: {
        width: "80%",
        height: "4px",
        backgroundColor: "#000000"
    },
    text: {
        fontSize: "14px",
        fontWeight: 600
    },
    bxinfo: {
        height: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
    }
}),
);

const Overlay = (props: any) => {
    const text: string = "rder & eat now";
    const { classes } = useStyles()
    return props.openOverlay ? (
        <div className={classes.overlayStyle}>
            <Box id="overlay-rder-&-eat-now" sx={{ display: "flex", width: "260px", justifyContent: "space-between", alignItems: "center" }}>
                <Box className={classes.bxinfo}>
                    <Box className={classes.line}></Box>
                    <Typography style={{ transform: "matrix(-1, 0, 0, 1, 0, 0)" }} variant="body1" color="initial" className={classes.text}>{text}</Typography>
                </Box>
                <Box>
                    <CircularProgress color="inherit" size={60} />
                </Box>
                <Box className={classes.bxinfo}>
                    <Box className={classes.line}></Box>
                    <Typography variant="body1" color="initial" className={classes.text}>{text}</Typography>
                </Box>

            </Box>
        </div>
    ) : null
}

export default Overlay