export const iconHome = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/iconHome.svg'
export const StarIcon = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/Star.svg'
export const WarningIcon = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/Warning.svg'
export const bugFixingImage = 'https://storage.googleapis.com/order-and-eat-now/Images/Images/bug_fixing.svg'
export const menuOurServices_icon = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/menuOurServices_icon.svg'
export const editContent_icon = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/editContent_icon.svg'
export const LocalMall = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/bolso.svg'
export const Coffee = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/taza_cafe.svg'
export const Motorcycle = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/moto.svg'
export const CarRentalIcon = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/rent.svg'
export const Car = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/carro.svg'
export const RoomService = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/habitacion.svg'
export const TableBar = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/mesa.svg'
export const QueryBuilder = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/fila.svg'
export const backIcon = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/back.svg'
export const menuAlignLeft = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/menuAlignLeft.svg'
export const carShop = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/carShop.svg'